<template>
  <v-card flat>
    <v-navigation-drawer v-model="showFilter" fixed temporary>
      <v-card-title class="body-1 font-weight-bold py-2">{{ $t('filters') }}</v-card-title>
      <v-divider></v-divider>
      <v-col cols="12" lg="12" sm="12" xs="6">
        <v-autocomplete v-model="filter.mailbox" :items="listOfMailBoxForFilter" item-text="name" item-value="_id" :label="$t('mailbox')" dense hide-details outlined></v-autocomplete>
      </v-col>
      <v-col cols="12" lg="12" sm="12" xs="6">
        <v-autocomplete v-model="filter.type" :items="listOfTypes" item-text="name" item-value="_id" :label="$t('type')" dense hide-details outlined></v-autocomplete>
      </v-col>
      <v-col cols="12" lg="12" sm="12" xs="6">
        <v-autocomplete v-model="ticketFilter" :items="listOfTicketFilter" item-text="text" item-value="id" :label="$t('filterType')" dense hide-details outlined></v-autocomplete>
      </v-col>
      <v-col cols="12" lg="12" sm="12" xs="6" v-if="[0,3,4].includes(ticketFilter)">
        <v-autocomplete v-model="filter.assignedto" :items="listOfUsersForFilter" item-text="name" item-value="alternateid" :label="$t('assignedTo')" dense hide-details outlined></v-autocomplete>
      </v-col>
      <v-col cols="12" lg="12" sm="12" xs="6">
          <v-autocomplete v-model="filter.priority" :items="listOfPrioritiesForFilter" item-text="name" item-value="_id" :label="$t('priority')" dense hide-details outlined ></v-autocomplete>
      </v-col>
      <v-col cols="12" lg="12" sm="12" xs="6" v-if="ticketFilter === 4">
          <v-autocomplete multiple v-model="filter.status" :items="listOfStatusForFilter" item-text="name" item-value="_id" :label="$t('status')" dense hide-details outlined></v-autocomplete>
      </v-col>
      <v-col cols="12" lg="12" sm="12" xs="6">
        <v-autocomplete v-model="filter.category" :items="listOfCategoryForFilter" item-text="name" item-value="_id" :label="$t('category')" dense hide-details outlined></v-autocomplete>
      </v-col>
      <v-col cols="12" lg="12" sm="12" xs="6" class="pt-0">
        <v-switch v-model="filter.unread" :label="$t('unread')" hide-details></v-switch>
      </v-col>
      <v-divider></v-divider>
      <v-col cols="12" lg="12" sm="12" xs="6" class="text-right">
        <v-btn color="primary" @click="filterData">{{ $t('apply') }}</v-btn>
      </v-col>
    </v-navigation-drawer>
    <!-- <v-card-title class="py-2 app-bg-color">
      <v-row>
        <v-col cols="12" lg="2" sm="4" xs="6" class="text-right">
          <v-text-field append-icon="mdi-magnify" single-line outlined dense v-model="search" :label="$t('search')" class="text-xs-right" id="searchbtn" hide-details ></v-text-field>
        </v-col>
      </v-row>
    </v-card-title> -->
    <compact-list :payload="compactListPayload" :total="total" @update-selected-tickets="updateSelected" @search-term="updateSearch" :moduleName="moduleName"></compact-list>
    <!--Merge Ticket-->
    <v-dialog v-model="mergeTicketDialog" persistent max-width="600px" class="mt-0">
      <v-card>
        <v-card-title :style="`background-color:${systemDetails.themecolor};color:${systemDetails.textcolor}`" class="pa-3 pb-2 subtitle-1">
          {{ $t('ticketMerge') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-5">
          {{ $t('chooseParentTicket') }}
          <v-radio-group v-model="parentticketid" mandatory>
            <template v-for="(items, index) in mergeItems">
              <v-radio :label="items.number ? `${items.number} ${items.subject}`: `${items.subject}`" :value="items.id" :key="index"></v-radio>
            </template>
          </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn color="primary" small id="merge" @click="mergeTicketHandler">{{ $t('merge') }}</v-btn>
          <v-btn color="error" small id="mergeclose" @click="closeMergeDialogHandler">{{ $t('close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Status bulk change-->
    <v-dialog v-model="statusDialog" persistent max-width="600px" class="mt-0">
      <v-card>
        <v-card-title :style="`background-color:${systemDetails.themecolor};color:${systemDetails.textcolor}`" class="pa-2 pl-3 subtitle-1">
          {{ $t('changeStatus') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-5">
          <v-autocomplete v-model="changedStatusId" :items="getListOfTicketStatus" item-text="name" item-value="_id" :placeholder="$t('status')" hide-details outlined dense></v-autocomplete>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn small color="primary" id="status" @click="changedStatusHandler" :loading="statusLoading">{{ $t('update') }}</v-btn>
          <v-btn small color="error" id="statusclose" @click="closeStatusDialogHandler">{{ $t('close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
     <!--Priority bulk change-->
    <v-dialog v-model="priorityDialog" persistent max-width="600px" class="mt-0">
      <v-card>
        <v-card-title :style="`background-color:${systemDetails.themecolor};color:${systemDetails.textcolor}`" class="pa-2 pl-3 subtitle-1">
          {{ $t('changePriority') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-5">
          <v-autocomplete v-model="changedPriorityId" :items="getListOfTicketPriorities" item-text="name" item-value="_id" :placeholder="$t('priority')" hide-details outlined dense></v-autocomplete>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn small color="primary" id="priority" @click="changedPriorityHandler">{{ $t('update') }}</v-btn>
          <v-btn small color="error" id="priorityclose" @click="closePriorityDialogHandler">{{ $t('close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Assinged bulk change-->
    <v-dialog v-model="assignedDialog" persistent max-width="600px" class="mt-0">
      <v-card>
        <v-card-title :style="`background-color:${systemDetails.themecolor};color:${systemDetails.textcolor}`" class="pa-2 pl-3 subtitle-1">
          {{ $t('changeAssignedTo') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-5">
          <v-autocomplete v-model="assignedtoId" :items="getUsersAndGroups" item-text="name" item-value="alternateid" :placeholder="$t('assignedTo')" hide-details outlined dense></v-autocomplete>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn small color="primary" id="priority" @click="changeAssignedtoHandler">{{ $t('update') }}</v-btn>
          <v-btn small color="error" id="priorityclose" @click="closeAssignedtoDialogHandler">{{ $t('close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Delete-->
    <v-dialog v-model="deleteDialog" persistent max-width="400px" class="mt-0">
      <v-card>
        <v-card-title class="py-2 px-4 error white--text">
          {{ $t('deleteConfirm') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-3 pt-0 pb-1">
          <v-checkbox v-model="deleteMails" :label="$t('deleteMailsTo')"></v-checkbox>
          <span v-if="deleteMails" color="error">
           {{$t('note')}} : <code>{{$t('deleteNote')}}</code>
          </span>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn small color="error" id="priority" @click="deleteHandler">{{ $t('delete') }}</v-btn>
          <v-btn small color="info" id="priorityclose" @click="closeDeleteDialogHandler">{{ $t('close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-speed-dial bottom right fixed  class="custom-speed-dial">
      <template #activator>
        <v-btn small :color="systemDetails.themecolor" fab dark :style="`color: ${systemDetails.textcolor}`"><v-icon>mdi-plus</v-icon></v-btn>
      </template>
      <v-btn v-for="(menu, index) in menuList" :key="index" @click="menuActions(menu.value)" class="text-capitalize ma-0 mb-1" dark small min-width="80px"
      :style="`color: ${systemDetails.textcolor}`" :color="systemDetails.themecolor">{{ menu.title }}</v-btn>
    </v-speed-dial>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
import CompactList from '@/components/CompactList'
export default {
  props: ['hideFilters', 'moduleName', 'recordId'],
  data () {
    return {
      showFilter: false,
      filter: {
        status: [],
        assignedto: 0,
        priority: null,
        category: null,
        mailbox: null,
        type: 0,
        page: 1
      },
      selected: [],
      listOfTickets: [],
      search: '',
      mergeItems: [],
      loading: false,
      MODULE_URL: 'tickets',
      parentticketid: 0,
      mergeTicketDialog: false,
      statusDialog: false,
      priorityDialog: false,
      assignedDialog: false,
      changedStatusId: 0,
      changedPriorityId: 0,
      assignedtoId: 0,
      deleteMails: false,
      deleteDialog: false,
      total: 0,
      paginationList: {
        itemsPerPageOptions: [10, 25, 50, 100]
      },
      pagination: !this.$formatter.isEmptyObject(this.$store.state.common.TICKETS_INDEX) ? this.$store.state.common.TICKETS_INDEX : { itemsPerPage: 8, page: 1 },
      compactListPayload: {
        sortBy: 1,
        hideHeader: false,
        moduleTitle: 'Tickets',
        moduleIcon: 'mdi-palette-swatch',
        hasNoNext: false,
        loading: false,
        list: [],
        pagination: {},
        storeIndex: 'TICKETS_INDEX',
        items: [
          { text: this.$t('number'), value: 'number', cols: 6 },
          { text: this.$t('status'), value: 'statusname', cols: 6 },
          { text: this.$t('assignTo'), value: 'assignedtoname', cols: 12 },
          { text: this.$t('subject'), value: 'subject', cols: 12 }
        ],
        hasFilter: this.hideFilters ? this.hideFilters : true,
        isCustomModule: false,
        isTickets: true,
        menuActions: this.menuActions,
        viewClickHandler: (item) => this.$router.push(`/tickets/actions/${item.id}`),
        hasPermission: true,
        hasPagination: false,
        sortClickHandler: (type) => { this.getListHandler({}, type) }
      },
      ticketFilter: 4,
      // userSignature: '',
      storageName: '',
      statusLoading: false,
      sort: 1
    }
  },
  computed: {
    ...mapGetters(['getListOfTicketPriorities', 'getListOfMailboxes', 'getListOfTicketStatus', 'getUsers', 'getListOfTicketCategory', 'getsignalRConnection', 'getListOfGroups', 'getListOfTicketType', 'getAccountList', 'userDetails']),
    listOfMailBoxForFilter () {
      const list = this.$formatter.cloneVariable(this.getListOfMailboxes)
      list.splice(0, 0, { _id: 0, name: this.$t('all') })
      return list
    },
    menuList () {
      return [
        { title: this.$t('createTask'), value: 'createTask', icon: 'mdi-clipboard-check' },
        { title: this.$t('createTicket'), value: 'createTicket', icon: 'mdi-palette-swatch' }
      ]
    },
    listOfPrioritiesForFilter () {
      const list = this.$formatter.cloneVariable(this.getListOfTicketPriorities)
      list.splice(0, 0, { _id: 0, name: this.$t('all') })
      return list
    },
    listOfTypes () {
      return [
        { _id: 0, name: this.$t('all') },
        { _id: 1, name: this.$t('tickets') },
        { _id: 2, name: this.$t('tasks') }
      ]
    },
    listOfStatusForFilter () {
      const list = this.$formatter.cloneVariable(this.getListOfTicketStatus)
      return list
    },
    listOfUsersForFilter () {
      let list = this.$formatter.cloneVariable(this.getUsers)
      list = [...list, ...this.getListOfGroups]
      list.splice(0, 0, { alternateid: 0, name: this.$t('all') })
      list.splice(1, 0, { alternateid: -1, name: this.$t('notAssigned') })
      return list
    },
    listOfCategoryForFilter () {
      const list = this.$formatter.cloneVariable(this.getListOfTicketCategory)
      list.splice(0, 0, { _id: null, name: this.$t('all') })
      return list
    },
    getUsersAndGroups () {
      const userList = this.$formatter.cloneVariable(this.getUsers)
      const groupList = this.$formatter.cloneVariable(this.getListOfGroups)
      const list = [...userList, ...groupList]
      return list
    },
    listOfTicketFilter () {
      return [
        { id: 0, text: this.$t('allActive'), icon: 'mdi-pin' },
        { id: 1, text: this.$t('myOpenTickets'), icon: 'mdi-pin' },
        { id: 2, text: this.$t('unassingedTickets'), icon: 'mdi-pin' },
        { id: 3, text: this.$t('closed'), icon: 'mdi-pin' },
        { id: 4, text: this.$t('allTickets'), icon: 'mdi-pin' }
      ]
    }
  },
  mounted () {
    this.storageName = !this.hideFilters ? `${process.env.VUE_APP_STORE_NAME}_ticketFilter` : `${process.env.VUE_APP_STORE_NAME}_${this.moduleName}_ticketFilter`
    const localStorage = window.localStorage.getItem(this.storageName)
    if (localStorage && !this.$formatter.isEmptyObject(JSON.parse(localStorage))) {
      this.filter = JSON.parse(localStorage)
    } else this.$set(this.filter, 'assignedto', `user_${this.userDetails._id}`)
    this.compactListPayload.loading = true
    this.$store.dispatch('getAccounts')
    this.$store.dispatch('getTicketPriority')
    this.$store.dispatch('getUsers')
    // if (this.getUsers.length > 0) {
    //   const currentUser = this.getUsers.find(x => x._id === this.userDetails._id)
    //   if (currentUser && currentUser.signature) this.userSignature = `<div><p></p><p style="white-space: pre-wrap;">${currentUser.signature.replace(/(?:\r\n|\r|\n)/g, '<br>')}</p></div>`
    // }
    this.$store.dispatch('getMailbox')
    this.$store.dispatch('getTicketStatus').then(() => {
      // for ticket listing in submodules - start
      if (this.hideFilters) {
        let accountId = null
        let moduleRelations = {}
        if (this.moduleName === this.ACCOUNT) accountId = this.recordId
        else moduleRelations = { id: this.recordId, module: this.moduleName }
        this.filter.account_id = accountId && moduleRelations ? accountId : null
        this.filter.modulerelation = moduleRelations && !accountId ? moduleRelations : undefined
      }
      this.getListHandler({}, 1)
    })
    this.$store.dispatch('getTicketCategory')
    this.$store.dispatch('getListOfGroups')
    // if (!this.$formatter.isEmptyObject(this.$store.state.common.F_TICKETS)) {
    //   const tickets = this.$formatter.cloneVariable(this.$store.state.common.F_TICKETS)
    //   this.filter = tickets.filter
    // }
    this.$eventBus.$on('loadList', (data) => {
      this.pagination.page += 1
      this.getListHandler(data)
    })
    this.$eventBus.$on('showHideFilter', () => {
      this.showFilter = !this.showFilter
    })
    // if (this.hideFilters) this.compactListPayload.fromSubModule = this.hideFilters // to hide actions for submodules
    // else this.getListHandler({}, 1)
  },
  watch: {
    search: function (newValue, oldValue) {
      this.compactListPayload.list = []
      this.getListHandler()
    }
  },
  components: {
    'compact-list': CompactList
  },
  methods: {
    getListHandler (paginationObj, sort) {
      if (sort) {
        this.sort = sort
        this.compactListPayload.list = []
        this.pagination.page = 1
      }
      this.selected = []
      window.localStorage.setItem(this.storageName, JSON.stringify(this.filter))
      this.$eventBus.$emit('resetSelectedTickets')
      this.compactListPayload.loading = true
      const pagination = paginationObj && paginationObj.itemsPerPage ? paginationObj : this.pagination
      this.compactListPayload.pagination.page = pagination.page
      this.pagination.page = pagination.page
      this.filter.rowsperpage = 25
      this.filter.page = pagination.page
      this.filter.search = this.search
      const filterItem = this.$formatter.cloneVariable(this.filter)
      // if (sort) {
      filterItem.descending = this.sort !== 1
      filterItem.sortBy = 'number'
      this.compactListPayload.sortBy = this.sort || this.compactListPayload.sortBy
      // }
      if (this.ticketFilter !== 4) filterItem.status = []
      if (![0, 3, 4].includes(this.ticketFilter)) filterItem.assignedto = null
      if (this.ticketFilter === 4 || this.ticketFilter === 3 || this.ticketFilter === 0) {
        if (filterItem.assignedto !== null && filterItem.assignedto !== 0 && filterItem.assignedto !== -1) {
          const value = filterItem.assignedto.split('_')
          if (value[0] === 'user') {
            filterItem.assignedto = value[1]
            filterItem.assignedtogroup = 0
          } else {
            filterItem.assignedtogroup = value[1]
            filterItem.assignedto = 0
          }
        }
      }
      switch (this.ticketFilter) {
        case 0: filterItem.status = this.getListOfTicketStatus.filter(x => x.isopen).map(x => x._id)
          break
        case 1: filterItem.assignedto = this.userDetails._id
          filterItem.status = this.getListOfTicketStatus.filter(x => x.isopen).map(x => x._id)
          break
        case 2: filterItem.assignedto = -1
          filterItem.assignedtogroup = -1
          break
        case 3:
          filterItem.status = this.getListOfTicketStatus.filter(x => x.canclose).map(x => x._id)
          break
      }
      // if (this.ticketFilter === 0)
      if (filterItem.assignedto === -1) filterItem.assignedtogroup = -1
      // To set ids with value to null for filter
      Object.keys(filterItem).forEach(function (key) {
        if (filterItem[key] === 0 && key !== 'status' && key !== 'totalItems') filterItem[key] = null
      })
      if (!filterItem.page) filterItem.page = 1
      if (filterItem.type === null) filterItem.type = 0
      this.$api.execute('post', 'tickets/paginate_filter', filterItem).then(response => {
        const ticketList = response.data.items
        this.total = response.data.total
        ticketList.forEach(element => { element.showEdit = false })
        const total = response.data.total
        const totalCount = Math.ceil(total / pagination.itemsPerPage)
        if (this.pagination.page === totalCount) this.compactListPayload.hasNoNext = false
        else this.compactListPayload.hasNoNext = false
        if (totalCount === 0) this.compactListPayload.hasNoNext = true
        const getCountParams = { totalCount, page: this.pagination.page, actionDelete: this.pagination.actionDelete }
        this.$eventBus.$emit('getCount', getCountParams)
        this.pagination.actionDelete = undefined
        this.compactListPayload.list = [...this.compactListPayload.list, ...ticketList]
      }).finally(() => {
        this.compactListPayload.loading = false
      })
    },
    updateSearch (data) {
      this.pagination.page = 1
      this.search = data
    },
    menuActions (value) {
      if (value) {
        switch (value) {
          case 'createTask':
            this.$router.push({ path: '/tickets/create', query: { type: 'task' } })
            break
          case 'createTicket':
            this.$router.push('/tickets/create')
            break
          case 'merge':
            this.mergeDialogHandler()
            break
          case 'changeStatus':
            this.actionsHandler('statusDialog')
            break
          case 'changePriority':
            this.actionsHandler('priorityDialog')
            break
          case 'assignTo':
            this.actionsHandler('assignedDialog')
            break
          case 'delete':
            this.actionsHandler('deleteDialog')
        }
      }
    },
    // addToSelected (event, item) {
    //   if (event) {
    //     this.selected.push(item)
    //   } else {
    //     const result = this.selected.filter(x => x.id !== item.id)
    //     if (result) {
    //       this.selected = result
    //     }
    //   }
    // },
    // showEditHandler (index) {
    //   this.listOfTickets[index].showEdit = true
    // },
    // hideEditHandler (index) {
    //   this.listOfTickets[index].showEdit = false
    // },
    // updateEditHandler (index) {
    //   const item = this.listOfTickets[index]
    //   const model = {
    //     status: item.status,
    //     assignedto: item.assignedto,
    //     priority: item.priority,
    //     category: item.category
    //   }
    //   this.$api.execute('put', `${this.MODULE_URL}/${item.id}`, model).then(response => {
    //     this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'updatedSuccess' })
    //     this.getListHandler()
    //   })
    // },
    mergeDialogHandler () {
      if (this.selected.length >= 2) {
        const items = this.$formatter.cloneVariable(this.selected)
        this.mergeItems = items
        this.mergeTicketDialog = true
      } else {
        this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'selectTickets' })
      }
    },
    actionsHandler (type) {
      if (this.selected.length > 0) this[type] = true
      else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'selectTickets' })
    },
    mergeTicketHandler () {
      const model = {}
      model.parentticketid = this.parentticketid
      model.childticketids = this.mergeItems.filter(x => x.id !== model.parentticketid).map(x => x.id)
      this.$api.execute('post', 'mails/merge_mail', model).then(response => {
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'mergeSuccess' })
        this.closeMergeDialogHandler()
        this.compactListPayload.list = []
        this.pagination.page = 1
        this.getListHandler()
        this.$eventBus.$emit('selectionModeToggle', false)
      })
    },
    closeMergeDialogHandler () {
      this.mergeItems = []
      this.parentticketid = 0
      this.mergeTicketDialog = false
    },
    changedStatusHandler () {
      const model = {}
      this.statusLoading = true
      const idsArray = this.selected
      model.ids = idsArray.map(x => x.id)
      model.parameters = { status: this.changedStatusId }
      const status = this.getListOfTicketStatus.find(x => x._id === this.changedStatusId)
      this.$api.execute('put', 'tickets/bulk_update', model).then(response => {
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'updatedSuccess' })
        idsArray.forEach(element => {
          const msg = `Status set to ${status.name} for ${element.number} by ${this.userDetails.name}`
          if (this.userDetails._id !== element.assignedto) this.getsignalRConnection.invoke('SendNotification', [element.assignedto], msg, element.id)
        })
        this.statusLoading = true
        this.closeStatusDialogHandler()
        this.compactListPayload.list = []
        this.pagination.page = 1
        this.getListHandler()
      }).finally(() => this.$eventBus.$emit('selectionModeToggle', false))
    },
    closeStatusDialogHandler () {
      this.changedStatusId = 0
      this.statusDialog = false
    },
    changedPriorityHandler () {
      const model = {}
      const idsArray = this.selected
      model.ids = idsArray.map(x => x.id)
      model.parameters = { priority: this.changedPriorityId }
      const priority = this.getListOfTicketPriorities.find(x => x._id === this.changedPriorityId)
      this.$api.execute('put', 'tickets/bulk_update', model).then(response => {
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'updatedSuccess' })
        idsArray.forEach(element => {
          const msg = `Priority set to ${priority.name} for ${element.number} by ${this.userDetails.name}`
          if (this.userDetails.id !== element.assignedto) this.getsignalRConnection.invoke('SendNotification', [element.assignedto], msg, element.id)
        })
        this.closePriorityDialogHandler()
        this.compactListPayload.list = []
        this.pagination.page = 1
        this.getListHandler()
      }).finally(() => this.$eventBus.$emit('selectionModeToggle', false))
    },
    closePriorityDialogHandler () {
      this.changedPriorityId = 0
      this.priorityDialog = false
    },
    changeAssignedtoHandler () {
      const assignedToModel = {}
      const model = {}
      const idsArray = this.selected
      const assignedTo = this.assignedtoId.split('_')
      if (assignedTo[0] === 'user') {
        assignedToModel.assignedto = assignedTo[1]
        assignedToModel.assignedto_group = null
      } else {
        assignedToModel.assignedto_group = assignedTo[1]
        assignedToModel.assignedto = null
      }
      model.ids = idsArray.map(x => x.id)
      model.parameters = assignedToModel
      this.$api.execute('put', 'tickets/bulk_update', model).then(response => {
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'updatedSuccess' })
        idsArray.forEach(element => {
          const msg = `${this.$t('assignedtoMessage')} ${element.number} ${this.$t('by')} ${this.userDetails.name}`
          if (this.userDetails.id !== model.parameters.assignedto) this.getsignalRConnection.invoke('SendNotification', [parseInt(model.parameters.assignedto)], msg, element.id)
        })
        this.closeAssignedtoDialogHandler()
        this.compactListPayload.list = []
        this.pagination.page = 1
        this.getListHandler()
      }).finally(() => this.$eventBus.$emit('selectionModeToggle', false))
    },
    closeAssignedtoDialogHandler () {
      this.assignedtoId = 0
      this.assignedDialog = false
    },
    deleteHandler () {
      const ids = this.selected.map(x => x.id)
      if (ids.length === this.compactListPayload.list.length) {
        this.pagination.page -= 1
        this.pagination.actionDelete = true
      }
      const payload = { ids: ids, deleteMails: this.deleteMails, url: `${this.MODULE_URL}/delete_multiple`, module: this.MODULE_URL }
      this.$store.dispatch('deleteHandler', payload).then(response => {
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'deleteSuccess' })
        this.closeDeleteDialogHandler()
        this.compactListPayload.list = []
        this.pagination.page = 1
        this.getListHandler()
      }).finally(() => this.$eventBus.$emit('selectionModeToggle', false))
    },
    closeDeleteDialogHandler () {
      this.deleteMails = false
      this.deleteDialog = false
    },
    updateSelected (data) {
      this.selected = data
    },
    filterData () {
      this.showFilter = false
      const data = { type: 'F_TICKETS', data: { filter: this.filter } }
      this.$store.commit('saveFilter', data)
      this.compactListPayload.list = []
      this.pagination.page = 1
      this.getListHandler()
    }
  },
  beforeDestroy () {
    this.$eventBus.$off('loadList')
    this.$eventBus.$off('showHideFilter')
  }
}
</script>
<style>
.checkboxPadding .v-input--selection-controls {
  padding-top: 0px !important;
  margin-top: 0px !important;
}
.checkboxPadding .v-text-field--full-width input{
  margin-top: 0 !important;
}
.checkboxPadding .v-text-field--full-width > .v-input__control > .v-input__slot {
  min-height: auto !important;
}
.checkboxPadding .v-text-field.v-text-field--enclosed .v-input__append-inner {
  margin-top: 3px !important;
 }
.checkboxPadding .v-text-field.v-text-field--enclosed > .v-input__control > .v-input__slot {
  padding: 0 !important;
  font-size: 14px;
}
.checkboxPadding.v-data-table tbody tr:hover:not(.v-data-table__expanded__content) {
  background: none !important;
}
.custom-speed-dial {
  bottom: 11vh !important;
  right: 3vw !important;
}
.custom-speed-dial div.v-speed-dial__list {
  padding: 5px 0 !important;
  align-items: flex-end !important;
}
</style>
